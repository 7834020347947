import * as React from "react"

import Seo from "../components/seo"
import Features from "../components/features"
import Services from "../components/services"

const IndexPage = () => (
  <>
    <Seo title="ConecTimme - Elétrica & Eletrônica" />
    <Features />
    <Services />
  </>
)

export default IndexPage
