import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Carousel } from "react-bootstrap"

const StyledCarousel = styled(Carousel)`
  .carousel-indicators button {
    background-color: #999;
    height: 10px;
  }

  h2 {
    font-size: 2rem;
  }

  p,
  li {
    font-size: 1.125rem;
  }

  @media (min-width: 768px) {
    h2 {
      font-size: 3rem;
    }
  }
`

const CarouselImageContainer = styled.div`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  grid-template-rows: 1fr 80px 1fr auto;
  margin: 0 auto 40px;

  .gatsby-image-wrapper {
    border: 10px solid #fefefe;
    box-shadow: 0px 13px 30px rgb(0 0 0 / 7%);
    border-radius: 3px;
  }

  .services-img-1 {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
  .services-img-2 {
    grid-column: 2 / 4;
    grid-row: 2 / 4;
  }
  .services-content {
    grid-column: 1 / 4;
    grid-row: 4 / 5;

    padding: 15px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 60px 1fr 60px 1fr;
    grid-template-rows: 1fr 100px 1fr;
    margin: 50px auto 75px;

    .services-img-1 {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
    }
    .services-img-2 {
      grid-column: 2 / 4;
      grid-row: 2 / 4;
    }
    .services-content {
      grid-column: 4 / 5;
      grid-row: 1 / 4;
      align-self: center;
    }
  }
`

const Info = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  .services-content {
    max-width: 800px;
    margin-top: 100px;
    margin-bottom: 70px;
    padding: 20px;
  }
`

const Services = () => (
  <>
    <StyledCarousel interval={8000} controls={false}>
      <Carousel.Item>
        <CarouselImageContainer>
          <div className="services-img services-img-1">
            <StaticImage
              src="../images/service7.jpg"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Manutenção em Tv's e Smartv's"
            />
          </div>

          <div className="services-img services-img-2">
            <StaticImage
              src="../images/service8.jpg"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Manutenção em Tv's e Smartv's"
            />
          </div>

          <div className="services-content">
            <h2>Projeto Painéis de Comando</h2>
            <ul>
              <li>Projeto</li>
              <li>Instalação</li>
              <li>Manutenção</li>
            </ul>
          </div>
        </CarouselImageContainer>
      </Carousel.Item>

      <Carousel.Item>
        <CarouselImageContainer>
          <div className="services-img services-img-1">
            <StaticImage
              src="../images/service10.jpg"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="parametrização do inversor"
            />
          </div>

          <div className="services-img services-img-2">
            <StaticImage
              src="../images/service6.jpg"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Alteração do esquema elétrico"
            />
          </div>

          <div className="services-content">
            <h2>
              Manutenção Eletrônica - Inversores de Frequência / Soft Start
            </h2>
            <p>Instalação e parametrização.</p>
          </div>
        </CarouselImageContainer>
      </Carousel.Item>

      <Carousel.Item>
        <CarouselImageContainer>
          <div className="services-img services-img-1">
            <StaticImage
              src="../images/service9.jpg"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="parametrização do inversor"
            />
          </div>

          <div className="services-img services-img-2">
            <StaticImage
              src="../images/service5.jpg"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Alteração do esquema elétrico"
            />
          </div>

          <div className="services-content">
            <h2>Manutenção Eletrônica Industrial Geral</h2>
            <ul>
              <li>Inversores</li>
              <li>Controladores de temperatura</li>
              <li>Reles de contato</li>
              <li>Caixa de ignição</li>
              <li>Motores</li>
              <li>Fonte de alimentação dc</li>
            </ul>
          </div>
        </CarouselImageContainer>
      </Carousel.Item>

      <Carousel.Item>
        <CarouselImageContainer>
          <div className="services-img services-img-1">
            <StaticImage
              src="../images/service3.jpg"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="parametrização do inversor"
            />
          </div>

          <div className="services-img services-img-2">
            <StaticImage
              src="../images/service4.jpg"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Alteração do esquema elétrico"
            />
          </div>

          <div className="services-content">
            <h2>Manutenção Em Painéis De Comando</h2>
            <ul>
              <li>Contatores</li>
              <li>Soft Start</li>
              <li>IHM</li>
              <li>Reles</li>
              <li>CLP</li>
              <li>Encoders</li>
              <li>Temporizadores</li>
            </ul>
          </div>
        </CarouselImageContainer>
      </Carousel.Item>
    </StyledCarousel>

    <Info>
      <div className="services-content">
        <h2>Solicite seu orçamento sem compromisso</h2>
        <p>
          Buscamos e entregamos no conforto na sua casa, do seu comércio ou da
          sua empresa. Orçamento sem compromisso. Pagamento facilitado em 10x
          s/juros. ConecTimme. Para você, para sua família e para sua empresa.
        </p>
      </div>
    </Info>
  </>
)

export default Services
